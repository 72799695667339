body{
  background-image: url(assets/tribelogo.jpg);
  background-repeat:repeat;
  background-size: cover;
  background-position: center;
}
.logo{
  height: 5rem;
  width: 5rem;
  background-color: black;
  border-radius: 50%;
}

.width{
  width: 100%;
}

.header1{
  height: auto;
  width: 100%;
  background-color: #00D911;
  text-align: center;
  opacity: 98%;
  contain: content;
}

.footer{
  margin-top: 10em;
  background-color: #00D911;
  margin-left: 0em;
  margin-right: 0em;
  margin-bottom: 0em;
}

.title{
  color: black;
  font-size: 5rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.navbar{
  background-color: #00D911;
  text-align: left;
}

.spacing1{
  margin-top: 5rem;
}

.bottomInfo{
  background-color: #235789;
  width: 100%;
  color: white;
  margin-top: 3em;
  height: auto;
  opacity: 98%;
  text-align: center;
}

.homeContent{
  height: auto;
  width: auto;
  contain: content;
  background-color: #235789;
  color: white;
  text-align: center;
  opacity: 98%;
  }
  
.homeContent2{
  height: auto;
  background-color: #2C1320;
  color: white;
  text-align: center;
  opacity: 98%;
  }

  .alert{
    height: auto;
    width: 100%;
    background-color: #d00202;
    text-align: center;
    opacity: 98%;
    contain: content;
    border-radius: 3%;
    color: aliceblue;
    
  }
  
.group1{
  margin-top:  5em;
}

.carouselImage{
  height: 50rem;
}
.carouselContainer{
  margin-top: 5rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: auto;
  }
.centerCarousel{
  text-align: center;
}
.carouselText{
  background-color: #00D911;
  color: #2C1320;
  border: #2C1320 solid 8px;
}

.blackout{
  background-color: black;
  color: aliceblue;
}

.card{
  margin-top: 5rem;
  border-radius: 0% 0% 20% 0%;
  box-shadow: 10px 10px 10px #F3C53C;
  background-color: black;
  

}
.cardbody{
  color:white
}

.deck{
  display: grid;
  text-align: center;
 }

 .piercerBody{
   width: auto;
   height: auto;
   margin-top: 5rem;
   
 }

.pCard{
  width: 25rem;
  margin: auto;

}

.pImage{
  width: 25rem;
  height: 20rem;
}
.btn-primary{
  background-color: #00D911;
  border-radius: 20% 50% 50% 20%;
  color: black;
}
.aboutImage{
  height: 30rem;
  width: 30rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.spaceAround{
  justify-content: space-around;
}

.libraryPhoto{
  height: 22rem;
  width: 22rem;
}

.colorBlock{
  background-color: #235789;
  padding-bottom: 3rem;
}

.colorBlock2{
  background-color: #2C1320;
  padding-bottom: 3rem;
}

.buffer{
  margin-top: 5rem;
}

.contact{
  background-color:#235789;
  text-align: center;
  width: 70%;
  height: auto;
  margin: auto;
  margin-top: 10rem;
  margin-bottom: 20rem;
  color: aliceblue;
  border:#2C1320 solid 8px;
}

.formStyle{
  text-align: center;
  width: 50%;
  justify-content: center;

}
.contactText{
  padding-bottom: 50%;
}
.schedule{
  text-align: left;
}
.BottomImage{
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
  border: #2C1320 1px;
}
.homeContent3{
  height: auto;
  width: auto;
  contain: content;
  background-color: #235789;
  color: white;
  text-align: center;
  opacity: 98%;
  }

  .bigger{
    font-size: 2rem;
    font-weight: bold;
  }

.bold{
  font-size: 2em;
  font-weight: bold;
}
